<template>

   
  <div class="flex-column" style="height: 100%;">
    <div class="padding-lr-20">
      <el-input placeholder="请输入关键词检索" clearable v-model.trim="keyword" @keyup.enter.native="initSearch" style="width: 400px;">
        <el-button slot="append" icon="el-icon-search" @click="load"></el-button>
      </el-input>
    </div>
    <div v-if="list.length" class="flex-auto margin-top-20" style="overflow: auto;">
      <div class="column padding-tb-10 margin-lr-20 link" v-for="(item,index) in list" :key="index" @click="$root.jump({path:'/customerMap/goods',blank:true,query:{spid:item.SPID}})">
        <div class="font-size-16 bold">
          {{ item['商品名称'] }}
        </div>
        <div class="font-size-12 line-height-large">
          <div class="margin-right-20">规格：{{ item['规格'] }}</div>
          <div class="margin-right-20">生产厂家：{{ item['生产厂家'] }}</div>
          <div class="margin-right-20">商品编号：{{ item['商品编号'] }}</div>
        </div>
        
      </div>
    </div>
    <el-empty v-else :image-size="100" class="flex-auto"> </el-empty>
  </div>
    
  </template>
  <script>
  
  export default {
    name: "DistributionOfGoods",
    props:{
     
    },
    data() {
    return {
      keyword:'',
      list:[],
      total:0,
      loading:false
    }
  },
    mounted() {
      this.load()
    },
    methods: {
      load(){

        this.loading=true
        let params= {
          ...this.$store.state.basicParams,
          keyword:this.keyword
        }
        this.$instance.get('/khdt/get_ph_product_list',{params})
        .then(res=>{
          this.loading=false
          if(res.data.code==0){
            this.list=res.data.data.list||[]
            this.total=res.data.data.total
          }
        })
        .catch(()=> {
          this.loading=false
        }); 
      },
    },
    watch: {
      
    }
  };
  </script>
  <style scoped>

  </style>