<template>
  <div class="padding-right-10" style="height: 100%; position: relative;" v-loading="loading">
    <div class="padding-lr-10" style="height: calc(100% - 60px); overflow: auto;">
    <ul>
      <li class="flex-row border-bottom padding-tb-10">
        <span class="text-nowrap">单位名称：</span>
        <span class="text-nowrap">{{khhx.dwmch}}</span>
      </li>
      <li class="flex-row border-bottom padding-tb-10">
        <span class="text-nowrap">联系人电话：</span>
        <span>{{khhx.lxr_tel}}</span>
      </li>
      <li class="flex-row border-bottom padding-tb-10">
        <span class="text-nowrap">单位地址：</span>
        <span>{{khhx.dzhdh}}</span>
      </li>
      <li class="flex-row border-bottom padding-tb-10">
        <span class="text-nowrap">业务员姓名：</span>
        <span>{{khhx.ywy}}</span>
      </li>
      <li class="flex-row border-bottom padding-tb-10">
        <span class="text-nowrap">客服姓名：</span>
        <span>{{khhx.kefu}}</span>
      </li>
    </ul>
    <el-form :model="userForm" ref="userForm" label-width="auto" style="margin-top:20px">
      <el-form-item label="备注信息" prop="bz" :rules="[{ required: true, message: '此项不能为空'}]" >
        <el-input v-model.trim="userForm.bz" autocomplete="off" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="账期" prop="zq" :rules="[{ required: true, message: '此项不能为空'}]" >
        <el-input v-model.trim="userForm.zq" autocomplete="off" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="配送费" prop="psf" :rules="[{ required: true, message: '此项不能为空'}]" >
        <el-input v-model.trim="userForm.psf" autocomplete="off" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="配送难度" prop="psnd" :rules="[{ required: true, message: '此项不能为空'}]" >
        <el-input v-model.trim="userForm.psnd" autocomplete="off" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="毛利因素" prop="mlys" :rules="[{ required: true, message: '此项不能为空'}]" >
        <el-input v-model.trim="userForm.mlys" autocomplete="off" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="销售规模" prop="xsgm" :rules="[{ required: true, message: '此项不能为空'}]" >
        <el-input v-model.trim="userForm.xsgm" autocomplete="off" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="客类" prop="kl" :rules="[{ required: true, message: '此项不能为空'}]" >
        <el-input v-model.trim="userForm.kl" autocomplete="off" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="客观指数" prop="kgzs" :rules="[{ required: true, message: '此项不能为空'}]" >
        <el-input v-model.trim="userForm.kgzs" autocomplete="off" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="定价指数" prop="djzs" :rules="[{ required: true, message: '此项不能为空'}]" >
        <el-input v-model.trim="userForm.djzs" autocomplete="off" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="指数毛利率" prop="zsmll" :rules="[{ required: true, message: '此项不能为空'}]" >
        <el-input v-model.trim="userForm.zsmll" autocomplete="off" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="预期毛利率" prop="yqmll" :rules="[{ required: true, message: '此项不能为空'}]" >
        <el-input v-model.trim="userForm.yqmll" autocomplete="off" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="定价等级" prop="djdj" :rules="[{ required: true, message: '此项不能为空'}]" >
        <el-input v-model.trim="userForm.djdj" autocomplete="off" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="等价指标" prop="djzb" :rules="[{ required: true, message: '此项不能为空'}]" >
        <el-input v-model.trim="userForm.djzb" autocomplete="off" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="药店面积" prop="ydmj" :rules="[{ required: true, message: '此项不能为空'}]" >
        <el-input v-model.trim="userForm.ydmj" autocomplete="off" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="周期" prop="zhq" :rules="[{ required: true, message: '此项不能为空'}]" >
        <el-input v-model.trim="userForm.zhq" autocomplete="off" placeholder="请输入"></el-input>
      </el-form-item>

    </el-form>
    </div>
    <div class="bottom-bar padding-top-10 flex-row align-items-center justify-center">
        <div>
          <el-button type="primary" round :loading="loading" @click="submitForm('userForm')">提交</el-button>
        </div>
      </div>
  </div>
    
  </template>
  <script>
  
  export default {
    name: "CustomerPortrait",
    props:{
      customerParams:{
        type:Object,
        default:()=>{
          return {}
        }
      },
      khhx:{
        type:Object,
        default:()=>{
          return {}
        }
      }
    },
    data() {
      return {
        loading:false,
        userForm:{}
      }
    },
    created(){
      this.userForm=JSON.parse(JSON.stringify(this.khhx))
    },
    mounted() {

    },
    methods: {
      submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.setKhhx()
        } else {
          return false;
        }
      });
    },
    setKhhx(){
      this.loading=true
      let formData = new FormData();
      formData.append('dwbh',this.customerParams.dwbh)
      // 将上传的文件放到数据对象中
      for(let key in  this.userForm){
        formData.append(key, this.userForm[key]);
      }
      this.$instance.post('/khdt/update_user_khhx',formData)
      .then(res=>{
        this.loading=false
        if(res.data.code==0){
          this.$message({message:'客户画像更新成功',type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
          this.$emit('updateDetail')
        }
      })
      .catch(()=> {
        this.loading=false
      }); 
    }
    },
    watch: {
      'khhx':{
        handler(){
          this.userForm=JSON.parse(JSON.stringify(this.khhx))
        },
        deep:true
      },
    }
  };
  </script>
  <style scoped>
  .bottom-bar{
    height: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  </style>