<template>

   
  <div class="flex-column" style="height: 100%;" v-loading='loading'>
    <div class="padding-lr-20">
    <el-tabs v-model="zizhi_state" @tab-click="initSearch">
      <el-tab-pane label="全部" name="0"></el-tab-pane>
      <el-tab-pane label="证照即将超期" name="2"></el-tab-pane>
      <el-tab-pane label="证照超期" name="3"></el-tab-pane>
    </el-tabs>
    <div class="flex-row align-items-center justify-between border-bottom padding-bottom-10">
      <div>开户状态：
        <el-select v-model="user_state" size="small" @change="initSearch">
          <el-option v-for="item in userStateList" :key="item.vlaue" :value="item.value" :label="item.name"></el-option>
        </el-select>
      </div>
      <div class="color-sub">共{{ total }}家客户</div>
    </div>
    </div>
    <div v-if="list.length" class="flex-auto padding-lr-20" style="overflow: auto;" v-infinite-scroll="load" infinite-scroll-distance="10" infinite-scroll-immediate="false" :infinite-scroll-disabled="scrollDisabled">
      <div class="padding-lr-20 padding-tb-10 border-bottom" v-for="(item,index) in list" :key="index">
        <div class="link" @click="clickCustomer(item)">
          <span class="custom-tag margin-right-5" :color="item.zizhi_state==1?'green':item.zizhi_state==2?'yellow':item.zizhi_state==3?'red':''">{{ item.zizhi_state==1?'证照正常':item.zizhi_state==2?'证照临期':item.zizhi_state==3?'证照超期':'' }}</span>
          <span class="font-size-16">{{ item.dwmch }}</span>
          <span class="color-sub">（{{ item.danwbh }}）</span>
        </div>
        <div class="flex-row align-items-center color-blue font-size-12 margin-top-5">
                  <div class="margin-right-20">本月采购{{ item.bycg }}</div>
                  <div>上月采购{{ item.sycg }}</div>
                </div>
        <div class="color-yellow font-size-12 margin-top-5">{{ item.user_type }}</div>
        <div class="flex-row align-items-center justify-between margin-top-5">
          <div class="color-sub">{{ item.dzhdh }}</div>
          <div class="text-nowrap margin-left-10 padding-left-10 border-left">{{ item.distance }}</div>
        </div>
      </div>
      <div v-if="loading" class="color-sub font-size-12 padding-20 text-align-center"><i class="el-icon-loading"></i> 数据加载中</div>
      <div v-if="scrollDisabled&&!loading" class="color-sub font-size-12 padding-20 text-align-center">加载完成</div>
    </div>
    <el-empty v-else :image-size="100" class="flex-auto"> </el-empty>
  </div>
    
  </template>
  <script>
  
  export default {
    name: "MyCustomers",
    props:{
      coordinate:{
        type:Array,
        default:()=>{
          return []
        }
      }
    },
    data() {
    return {
      zizhi_state:'0',
      user_state:3,
      userStateList:[{value:3,name:"全部"},{value:1,name:"已开户"},{value:0,name:"未开户"}],
      list:[],
      total:0,
      pageNum:1,
      pageSize:30,
      scrollDisabled:false,
      loading:false
    }
  },
    mounted() {
      this.initSearch()
    },
    methods: {
      initSearch(){

         this.list=[]
         this.pageNum=1
         this.scrollDisabled=false
         this.load()

      },
      load(){
        this.scrollDisabled=true
        this.loading=true
        let params= {
          ...this.$store.state.basicParams,
          page:this.pageNum,
          page_size:this.pageSize,
          gdjd:this.coordinate[0],
          gdwd:this.coordinate[1],
          user_state:this.user_state,
          zizhi_state:this.zizhi_state
        }
        this.$instance.get('/khdt/get_near_user_list',{params})
        .then(res=>{
          this.loading=false
          this.scrollDisabled=false
          if(res.data.code==0){
            this.list=this.list.concat(res.data.data.list||[])
            this.total=res.data.data.total
            //是否全部加载完成
            if(this.list.length>=res.data.data.total){
              this.scrollDisabled=true
            }else{this.pageNum+=1}
          }
        })
        .catch(()=> {
          this.loading=false
          this.scrollDisabled=false
        }); 
      },
      clickCustomer(data){
        this.$emit('clickCustomer',data)
      }
    },
    watch: {
      
    }
  };
  </script>
  <style scoped>

  </style>