<template>
    <div class="safe-area flex-row align-items-start margin-top-20" style="min-height:calc(100vh - 200px);">
      <div class="page-aside column" style="padding:0;min-height:calc(100vh - 200px);">
        <el-menu
          :router="true"
          :default-active="$route.path"
          @select="navSelect"
        >
          <el-menu-item index="/user/dataBoard" v-if="$store.getters.isSpecUser">
            <i class="el-icon-monitor"></i>
            <span slot="title">银河OA</span>
          </el-menu-item>
          <el-menu-item index="/user/home" v-else>
            <i class="el-icon-house"></i>
            <span slot="title">个人中心</span>
          </el-menu-item>
          <el-menu-item index="/user/orderFlow" v-if="$store.state.loginRes.jxc_right">
            <i class="el-icon-takeaway-box"></i>
            <span slot="title">进销存流向</span>
          </el-menu-item>
           <el-menu-item index="/customerMap" v-if="$store.getters.isYWY">
            <i class="el-icon-map-location"></i>
            <span slot="title">客户地图</span>
          </el-menu-item>
          <el-menu-item index="/user/CustomerInquiry" v-if="$store.getters.isYWY">
            <i class="el-icon-user"></i>
            <span slot="title">客户查询</span>
          </el-menu-item>
         
          <el-menu-item index="/user/order">
            <i class="el-icon-document-copy"></i>
            <span slot="title">我的订单</span>
          </el-menu-item>
          
          <el-menu-item index="/user/collect">
            <i class="el-icon-star-off"></i>
            <span slot="title">商品收藏</span>
          </el-menu-item>
          <el-menu-item index="/user/buyHistory">
            <i class="el-icon-document-checked"></i>
            <span slot="title">购买记录</span>
          </el-menu-item>
          <el-menu-item index="/user/browsingHistory">
            <i class="el-icon-view"></i>
            <span slot="title">浏览记录</span>
          </el-menu-item>
          <el-menu-item index="/user/eval">
            <i class="el-icon-lollipop"></i>
            <span slot="title">我的评价</span>
          </el-menu-item>
          <el-menu-item index="/user/coupon">
            <i class="el-icon-s-ticket"></i>
            <span slot="title">我的优惠券</span>
          </el-menu-item>
          <el-submenu index="xin_xi_wei_hu">
            <template slot="title">
              <i class="el-icon-edit-outline"></i>
              <span>信息维护</span>
            </template>
            <el-menu-item index="/user/authentication">资料认证</el-menu-item>
            <el-menu-item index="/user/address">地址管理</el-menu-item>
            <el-menu-item index="/user/changePassword">密码修改</el-menu-item>
          </el-submenu>
        </el-menu>
        
      </div>
      <div class="flex-auto flex-column" style="min-height:calc(100vh - 200px); padding:0 0 0 20px; overflow: auto;">
        <keep-alive><router-view v-if="$route.matched.some(val=>{return val.meta.keepalive})" class="container"></router-view></keep-alive>
        <router-view v-if="!$route.matched.some(val=>{return val.meta.keepalive})" class="container"></router-view>
      </div>
    </div>
    
</template>

<script>

export default {
  components: { },
  name: 'User',
  data() {
    return {

    }
  },
  computed:{

  },
  methods:{
    navSelect(index){
      if(!index){
        this.$root.tips()
      }
    }
  },
  created(){

  },
  mounted(){

  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .container{
    box-sizing: border-box;
    position: relative;
  }
</style>
