<template>
  <div>
    <div id="mapContainer"></div>
   <div  style="position: absolute; top:15px; left:30%; right:30%">
     <el-select style="width:100%" v-model="spid" v-if="goodsList.length">
       <el-option v-for="item in goodsList" :key="item.SPID" :value="item.SPID" :label="item['商品名称']">
         <div>{{ item['商品名称'] }}</div>
        <div class="font-size-12 color-sub">{{ item['规格'] }}</div>
        <div class="font-size-12 color-sub">{{ item['生产厂家'] }}</div>
       </el-option>
     </el-select>
    </div>
   
  </div>
    
  </template>
  <script>
  import AMapLoader from "@amap/amap-jsapi-loader";
  
  export default {
    name: "goodsrMap",
    components:{},
    props:{
     
    },
    data() {
      return {
        AMap:null,
        map:null,
        customerLabel:null,
        zoom:12,//地图默认缩放
        zooms:[2,20],//地图缩放范围
        spid:'',
        goodsList:[],
        provinceData:{
          list:[],
          markers:[]
        },
        cityData:{
          list:[],
          markers:[]
        },
        districtData:{
          list:[],
          markers:[]
        },
      }
    },
    computed:{
      
    },
    created(){
      this.spid=this.$route.query.spid
    },
    mounted() {
      this.initAMap();
      
    },
    unmounted() {
      this.mapDestroy()
    },
    destroyed(){
     this.mapDestroy()
    },
    methods: {
      mapDestroy(){
        this.map?.destroy();

      },
      //初始化地图
      async initAMap() {
        await this.creatMap()
        let AMap=this.AMap
        //客户锚点label
        this.customerLabel = new AMap.Marker({
          map: this.map,
          content:' ',
          offset:[15,10]
        });
        this.getGoods()
        //获取地区统计数据点
        this.getAggregation(1)
        this.getAggregation(2)
        this.getAggregation(3)
      },
      creatMap(){
       let promise = AMapLoader.load({
          key: "e5d9bafed04f7fd3c538203613c99a30", // 申请好的Web端开发者Key，首次调用 load 时必填
          version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          plugins: ['AMap.Geolocation','AMap.CitySearch'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        })
        promise.then((AMap) => {
          this.AMap=AMap
          //地图
          this.map = new AMap.Map("mapContainer", {
            // 设置地图容器id
            viewMode: "2D", // 是否为3D地图模式
            mapStyle: 'amap://styles/whitesmoke',  //设置地图的显示样式
            zoom: this.zoom, // 初始化地图级别
            zooms:this.zooms,
            doubleClickZoom:true
          });
           //添加控件  
          AMap.plugin(
            [
              "AMap.ToolBar",
              "AMap.Scale",
              "AMap.HawkEye",
              "AMap.MapType",
              "AMap.Geolocation",
              "AMap.ControlBar",
            ],
            ()=>{
              //添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
              this.map.addControl(new AMap.ToolBar());
              //添加比例尺控件，展示地图在当前层级和纬度下的比例尺
              this.map.addControl(new AMap.Scale());
              // this.map.addControl(new AMap.HawkEye());
            }
          ); 
          
        })
        promise.catch((e) => {
          console.log(e)
          this.$message({message:'地图加载失败',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        });
        return promise
      },
      

    
      getGoods(){
        let params= {
          ...this.$store.state.basicParams,
          keyword:''
        }
        this.$instance.get('/khdt/get_ph_product_list',{params})
        .then(res=>{

          if(res.data.code==0){
            this.goodsList=res.data.data.list||[]
            !this.spid&&(this.spid=(this.goodsList[0]||{}).SPID||'')
          }
        })
        .catch(()=> {

        }); 
      },
       //获取聚合数据
      getAggregation(type){
        if(!this.spid){
          return false
        }
        let params= {
          ...this.$store.state.basicParams,
          tj_type:type,
          spid:this.spid
        }
        this.$instance.get('/khdt/get_ph_product_data',{params})
        .then(res=>{
          if(res.data.code==0){
            switch(type){
              case 1:
                this.districtData.list=res.data.data.list||[];
                break;
              case 2:
                this.cityData.list=res.data.data.list||[];
                break;
              case 3:
                this.provinceData.list=res.data.data.list||[];
                break;
            }
            this.setAggregationMarker(type)
          }
        })
        .catch(()=> {}); 
      },
       //设置聚合marker
      setAggregationMarker(type){
        let AMap=this.AMap
        let areaData=(type==1?this.districtData:type==2?this.cityData:type==3?this.provinceData:this.districtData)
        for(let item of areaData.list.filter(v=>v.gdjd&&v.gdwd)){
          const markerContent = `<div class="flex-column align-items-center justify-center" style="width:50px;height:50px;border-radius:100%;background:#fe5e12;color:#fff;box-shadow: 0 0 10px rgba(0,0,0,0.2);">
                                  <div class="text-overflow-ellipsis font-size-12 text-align-center" style="width:50px">${item.area_name}</div>
                                  <div>${item.tj_count}</div>
                                  </div>`
          let marker=new AMap.Marker({
              map:this.map,
              position: new AMap.LngLat(item.gdjd,item.gdwd),
              anchor: 'center',
              topWhenClick:true,
              content: markerContent,
              zIndex:12+item.user_state,
              zooms:type==1?[9,20]:type==2?[7,8.99]:[2,6.99]
          });
          areaData.markers.push(marker)
        }
        
      },
     
    },
    watch: {

      'spid':{
        handler(){
          this.getAggregation(1)
          this.getAggregation(2)
          this.getAggregation(3)
        },
        deep:true
      },
    }
  };
  </script>
  <style scoped>
  #mapContainer {
    width: 100%;
    height: calc(100vh - 160px);
  }
  </style>
  <style>
 #mapContainer .el-loading-mask{
      background-color: rgba(255, 255, 255, 0.1);
  }
</style>