<template>
    <div id="mapContainer"></div>
  </template>
  <script>
  import AMapLoader from "@amap/amap-jsapi-loader";
    import currentMarkerImg from "@/assets/images/car-icon.png"
  export default {
    name: "map-view",
    props:{
      points:{
        type:Array,
        default:()=>{
          return []
        }
      }
    },
    data() {
    return {
      AMap:null,
      map:null
    }
  },
    mounted() {
      this.initAMap();
    },
    unmounted() {
      this.map?.destroy();
    },
    destroyed(){
      this.map?.destroy();
    },
    methods: {
      initAMap() {
        window._AMapSecurityConfig = {
          securityJsCode: "d25d72c99bc08aae91943a6d124c7bdc",
        };
        AMapLoader.load({
          key: "e5d9bafed04f7fd3c538203613c99a30", // 申请好的Web端开发者Key，首次调用 load 时必填
          version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        })
          .then((AMap) => {
            this.AMap=AMap
            this.setMap()
          })
          .catch((e) => {
            console.log(e);
          });
      },
      setMap(){
        let AMap=this.AMap
        let center=this.points.length?[this.points[this.points.length-1].lng,this.points[this.points.length-1].lat]:[116.397428, 39.90923]
        this.map = new AMap.Map("mapContainer", {
          // 设置地图容器id
          viewMode: "2D", // 是否为3D地图模式
          mapStyle: 'amap://styles/whitesmoke',  //设置地图的显示样式
          zoom: 15, // 初始化地图级别
          center: center, // 初始化地图中心点位置
        });
        //轨迹
        let path = this.points.map(v=>{return new AMap.LngLat(v.lng,v.lat)});
        console.log(path)
        let polyline = new AMap.Polyline({
            path: path,  
            borderWeight: 1, // 线条宽度，默认为 1
            strokeWeight:5,
            strokeColor: 'red', // 线条颜色
            lineJoin: 'round' // 折线拐点连接处样式
        });
        this.map.add(polyline);
        //当前位置点
        let icon = new AMap.Icon({
          size: new AMap.Size(50, 30),    // 图标尺寸
            image: currentMarkerImg,  // Icon的图像
            imageSize: new AMap.Size(50,30)   // 根据所设置的大小拉伸或压缩图片
        });
        let marker = new AMap.Marker({
            position: new AMap.LngLat(center[0],center[1]),
            // offset: new AMap.Pixel(-25, -15),
            icon: icon, // 添加 Icon 图标
            // title: '最新位置',
            label:{
              content:'最新位置',
              offset:[0,-5],
              direction:'top'
            }
        });

        this.map.add(marker);
      },
      
    },
    watch: {
      'points':{
        handler(){
          this.AMap&&this.setMap()
        },
        deep:true
      }
    }
  };
  </script>
  <style scoped>
  #mapContainer {
    width: 100%;
    height: 100%;
  }
  </style>