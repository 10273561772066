<template>
  <div class="padding-right-10" style="height: 100%; position: relative;" v-loading="loading">
    <div class="padding-lr-10" style="height: calc(100% - 60px); overflow: auto;">
      <el-form :model="userForm" ref="userForm" label-width="auto" style="margin-top:20px">
        <div class="margin-top-20 margin-bottom-10 bold">更正客户信息</div>
        <el-form-item label="客户信息" prop="userInfo" :rules="[{ required: true, message: '此项不能为空'}]" >
          <el-input v-model.trim="userForm.userInfo" autocomplete="off" placeholder="请输入客户名称"></el-input>
        </el-form-item>
        <el-form-item label="上传图片" prop="fileList" :rules="[{ required: true, message: '此项不能为空'}]" >
          
          <el-upload 
            ref="upload" 
            list-type="picture-card"
            :limit="3" 
            accept=".jpg,.gif,.png,.jpeg" 
            :multiple="true"
            action=""
            :on-change="handleFileChange"
            :before-remove="handleFileRemove" 
            :on-exceed="exceed"
            :auto-upload="false"
            >
              <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <div class="margin-bottom-10 bold">更正客户状态</div>
        <el-form-item label="客户状态" prop="khzt" :rules="[{ required: true, message: '此项不能为空'}]" >
          <el-radio-group v-model="userForm.khzt">
            <el-radio label="未开户">未开户</el-radio>
            <el-radio label="已开户">已开户</el-radio>
            <el-radio label="不存在">不存在</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

     
      
    </div>
    <div class="bottom-bar padding-top-10 flex-row align-items-center justify-center">
        <div>
          <el-button type="primary" round :loading="loading" @click="submitForm('userForm')">提交</el-button>
        </div>
      </div>
  </div>
    
  </template>
  <script>
  
  export default {
    name: "CommitUserInfo",
    props:{
      customerParams:{
        type:Object,
        default:()=>{
          return {}
        }
      },
    },
    data() {
      return {
        loading:false,
        userForm:{
          userInfo:'',
          fileList:[],
          khzt:''
        },
      }
    },
    created(){
      
    },
    mounted() {

    },
    methods: {
      // 上传发生变化钩子
      handleFileChange(file, fileList) {
        this.userForm.fileList = fileList;
      },
      // 删除之前钩子
      handleFileRemove(file, fileList) {
        this.userForm.fileList = fileList;
      },
      clearFiles(){
        this.$refs.upload.clearFiles()
        this.userForm.fileList=[]
      },
      exceed(){
        this.$message({message: '只能上传3张图片，请先删除已选文件',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
      },
    
      uploadFile(){
        this.loading=true
        // 创建新的数据对象
        let formData = new FormData();
        formData.append('dwbh',this.customerParams.dwbh)
        // 将上传的文件放到数据对象中
        this.userForm.fileList.forEach(file => {
          formData.append('fk_img', file.raw);
        });
        // formData.append('order_img',this.fileList.map(v=>v.raw));
        formData.append('khzt',this.userForm.khzt)
        
        formData.append('user_info',this.userForm.userInfo)
        // console.log("提交前",formData.getAll('order_img'));
        
        this.$instance.post('/khdt/commit_user_info',formData,
          {
            timeout: 300000,
          } 
        ).then(res=>{
          this.loading=false
          if(res.data.code==0){
            this.$message({message: res.data.msg,type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)});
            this.$emit('updateDetail')
          }
        })
        .catch(()=>{
          this.loading=false
        })
      },
     
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.uploadFile()
          } else {
            return false;
          }
        });
      },
    },
    watch: {
      
    }
  };
  </script>
  <style scoped>
  .bottom-bar{
    height: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  </style>