<template>
  <div style="height: 100%; overflow-y: auto; overflow-x: hidden; position: relative;" v-loading="loading">
    <el-row :gutter="20" type="flex" v-if="list.length">
      <el-col :span="12" v-for="(item,index) in list" :key="index">
        <div class="certificateItem flex-auto flex-row align-items-center justify-between padding-20 margin-bottom-20">
          <div class="flex-row align-items-center">
            <div class="icon">
              <i class="el-icon-postcard bold"></i>
            </div>
            <div class="margin-left-20">
              <div class="font-size-18 bold">{{item.zizhi_name}}</div>
              <div>证件号码：{{ item.zizhi_num }}</div>
              <div>有效期限：{{ item.zizhi_date }}</div>
            </div>
          </div>
          <div>
            <el-button type="primary" round plain @click="viewImg(item.zizhi_pic)">查看证照</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <template v-else>
          <div class="flex-column" style="height: 100%">
            <el-empty description="该客户暂无证照资料" class="flex-auto">
              <!-- <el-button type="primary">去开户</el-button> -->
            </el-empty>
          </div>
        </template>
  </div>
    
  </template>
  <script>
  
  export default {
    name: "CustomerCertificate",
    props:{
      customerParams:{
        type:Object,
        default:()=>{
          return {}
        }
      }
    },
    data() {
    return {
      loading:false,
      list:[]
    }
  },
    mounted() {
      this.load()
    },
    methods: {
      
      load(){
        this.loading=true
        let params= {
          ...this.$store.state.basicParams,
          dwbh:this.customerParams.dwbh
        }
        this.$instance.get('/khdt/get_user_zizhi',{params})
        .then(res=>{
          this.loading=false

          if(res.data.code==0){
            this.list=res.data.data.list||[]
          }
        })
        .catch(()=> {
          this.loading=false
        }); 
      },
      viewImg(url){
        window.open(url)
      }
    },
    watch: {
      'customerParams':{
        handler(){
          this.load()
        },
        deep:true
      },
    }
  };
  </script>
  <style scoped>
  .certificateItem{
    border-radius: 5px;
    background: linear-gradient(to right,#e5e5fb,#fff2e6);

    position: relative;
    .icon{
      background: #f7eeec;
      height: 60px;
      width: 60px;
      border-radius: 5px;
      color: #ff9b6b;
      text-align: center;
      line-height: 60px;
      font-size: 36px;
    }
  }
  </style>