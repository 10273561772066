<template>
  <div class="mall">
    <TopToolBar style="position: fixed; width: 100%; top: 0; left: 0; z-index: 100;"></TopToolBar>
    <div style="height: 40px;"></div>
    <MallHeader :name="$route.meta.title"></MallHeader>
    <keep-alive><router-view v-if="$route.matched.some(val=>{return val.meta.keepalive})" class="container"></router-view></keep-alive>
    <router-view v-if="!$route.matched.some(val=>{return val.meta.keepalive})" class="container"></router-view>

  </div>
</template>

<script>
import TopToolBar from '@/components/TopToolBar.vue'
import MallHeader from '@/components/header/MallHeader.vue'
export default {
  components: { TopToolBar,MallHeader},
  name: 'Default',
  data() {
    return {

    }
  },
  computed:{

  },
  methods:{

  },
  created(){

  },
  mounted(){

  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .container{
    box-sizing: border-box;
    position: relative;
  }
</style>
