<template>

    <div class="column flex-auto" style="margin-bottom:0;">
      <div class="flex-row align-items-center justify-between border-bottom padding-bottom-10">
        <div class=" bold">客户查询</div>
      </div>
      <div v-loading="loading">
        <!-- <div>
          <div class="padding-left-10 padding-top-20 search" :style="{'background': '#f7f7f7','overflow':'hidden','max-height':searchOpened?'200px':'45px'}">
            <el-form :model="form" :inline="true" size="mini">
              <el-form-item label="入库日期">
                <el-date-picker
                  v-model="form.start_date"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                  style="width: 130px;">
                </el-date-picker> 至
                <el-date-picker
                v-model="form.end_date"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                  style="width: 130px;">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="关键词">
                <el-input v-model="form.keyword" placeholder="搜索关键词" style="width: 170px;"></el-input>
              </el-form-item>
              <el-form-item label="库存数量">
                <el-input-number v-model="form.start_kc" :min="0" :step="1" placeholder="数量" style="width: 120px;"></el-input-number> 至 
                <el-input-number v-model="form.end_kc" :min="0" :step="1" placeholder="数量" style="width: 120px;"></el-input-number>
              </el-form-item>
              <el-form-item label="供应商">
                <el-input v-model="form.gys" placeholder="供应商" style="width: 170px;"></el-input>
              </el-form-item>
              <el-form-item label="医保编码">
                <el-input v-model="form.ybbm" placeholder="医保编码" style="width: 170px;"></el-input>
              </el-form-item>
              <el-form-item label="批准文号">
                <el-input v-model="form.pzwh" placeholder="批准文号" style="width: 170px;"></el-input>
              </el-form-item>
              <el-form-item label="标识">
                <el-input v-model="form.dm" placeholder="标识" style="width: 160px;"></el-input>
              </el-form-item>

              <el-form-item label="生产厂商">
                <el-input v-model="form.sccs" placeholder="生产厂商" style="width: 200px;"></el-input>
              </el-form-item>
              <el-form-item label="批号">
                <el-input v-model="form.ph" placeholder="批号" style="width: 200px;"></el-input>
              </el-form-item>
              <el-form-item label="业务员">
                <el-input v-model="form.ywy" placeholder="业务员" style="width: 200px;"></el-input>
              </el-form-item>
            
              
            </el-form>
          </div>
          <div class="flex-row justify-center margin-bottom-10" style="position: relative; height: 30px;">
            <div></div>
            <div style="background: #f7f7f7; padding: 0 10px;" class="flex-row align-items-center link margin-left-10" @click="searchOpened=!searchOpened">
              <i :class="searchOpened?'el-icon-arrow-up':'el-icon-arrow-down'"></i> {{searchOpened?'收起':'展开'}}
            </div>
            <div style="position: absolute; right: 0; top: 5px;">
              <el-button type="primary" size="small" @click="search">查询</el-button>
            </div>
            
          </div>
        </div> -->

        <div>
          <el-table
            :data="data.list"
            :max-height="$root.docHeight-230"
            size="mini"
            stripe
            style="width: 100%">
            <el-table-column
            :min-width="item=='单位名称'||item=='地址'?200:100"
            v-for="(item,index) in data.tableHeader" :key="index"
            :label="item">
            <template slot-scope="props">
              <div>{{props.row[item]}}</div>
            </template>
            </el-table-column>
            
          </el-table>
          <div class="flex-row justify-center margin-top-20">
              <el-pagination
                background
                layout="prev, pager, next"
                :hide-on-single-page="true"
                :total="page.total"
                :page-size="page.page_size"
                :current-page.sync="page.cur_page"
                @current-change='pageChange'
              >
              </el-pagination>
            </div>
        </div>
      </div>
    </div>

</template>

<script>

export default {
  components: {},
  name: 'CustomerInquiry',
  data() {
    return {
      loading:false,
      searchOpened:false,
      form:{
        
      },
      page:{
        total:0,//总条目
        cur_page:1,//页码
        page_size:15,//每页条数
      },
      data:{
        list:[],
        tableHeader:[]
      },
     
    }
  },
  computed:{
    checkDate(){
      let start=new Date(this.form.start_date).getTime(),
          end=new Date(this.form.end_date).getTime()
        if(start&&end&&start>end){
          return false
        }else{
          return true
        }
    },
    checkKc(){
      let start=this.form.start_kc,
          end=this.form.end_kc
        if(start&&end&&start>end){
          return false
        }else{
          return true
        }
    },
  },
  methods:{
     //获取列表
     getList(){
      if(!this.checkDate){
        this.$message({message: '入库开始时间应小于结束时间',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false
      }
      if(!this.checkKc){
        this.$message({message: '库存数量查询范围开始应小于结束',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false
      }
      this.loading=true;
      this.$instance.get('/ywy/get_customer_info_list',{
        params:{
          ...this.$store.state.basicParams,
          page:this.page.cur_page,
          page_size:this.page.page_size,
          ...this.form
        }
      })
      .then(res=>{
        this.loading=false;
        if(res.data.code==0){
          this.data.list=res.data.data.list
          this.data.tableHeader=res.data.data.table_head
          this.page.total=res.data.data.total
        }
      })
      .catch(()=>{
        this.loading=false;
      })

    },
    //切页
    pageChange(res){
      this.page.cur_page=res
      this.getList()
    },
    //条件查询
    search(){
      this.page.cur_page=1
      this.getList()
    },

  },
  created(){

    this.getList();
    
  },
  mounted(){ 
   
  },
  beforeDestroy() {

  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
<style>

</style>
