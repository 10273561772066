<template>
  <div>
    <div id="mapContainer" v-loading="loading"></div>
    <CustomerListBySearch :coordinate="coordinate" @clickCustomer="viewCustomerDetail"></CustomerListBySearch>
    <div class="flex-column" style="position: absolute; right:16px; top:80px">
      
    </div>
    <div class="flex-column align-items-end" style="position: absolute; right:16px; bottom:80px">
      <!-- <el-slider
        v-model="zoom"
        vertical
        :min="zooms[0]"
        :max="zooms[1]"
        :step="0.1"
        @change="setMapZoom"
        height="150px">
      </el-slider> -->
      <div @click="myCustomerVisible=true" class="column flex-column align-items-center justify-center link" style="padding:0px; width: 60px; height:60px;">
        <i class="el-icon-user font-size-16"></i>
        <span class="font-size-12 margin-top-5">我的客户</span>
      </div>
      <div @click="distributionOfGoodsVisible=true" class="column flex-column align-items-center justify-center link" style="padding:0px; width: 60px; height:60px;">
        <i class="el-icon-suitcase font-size-16"></i>
        <span class="font-size-12 margin-top-5">查看铺货</span>
      </div>
       
      <el-popover
        placement="right"
        title="筛选"
        width="300"
        trigger="hover"
        >
        <div>
          <div class="bold">客户状态</div>
          <div class="flex-row flex-wrap">
           <div class="custom-btn margin-5" :color="customFilter.user_state==item.value?'orange':''" v-for="item in customFilter.user_state_list" :key="item.value" size="small"
           @click="customFilter.user_state=item.value;getCustomerList();getAggregation(1);getAggregation(2);getAggregation(3);"
           >{{item.name}}</div>
          </div>
          <div class="bold margin-top-10">其他分类</div>
          <div class="flex-row flex-wrap">
            <div class="custom-btn margin-5" :color="customFilter.khlb==''?'orange':''" size="small"
            @click="customFilter.khlb='';getCustomerList();getAggregation(1);getAggregation(2);getAggregation(3);"
           >全部</div>
           <div class="custom-btn margin-5" :color="customFilter.khlb==item?'orange':''" v-for="item in customFilter.khlbList" :key="item" size="small"
            @click="customFilter.khlb=item;getCustomerList();getAggregation(1);getAggregation(2);getAggregation(3);"
           >{{item}}</div>
          </div>
        </div>
        <div slot="reference" class="column flex-column align-items-center justify-center link" style="padding:0px; width: 40px; height: 40px;">
          <i class="fa fa-filter font-size-16"></i>
        </div>
      </el-popover>
      <div @click="setZoomAndCenter" class="column flex-column align-items-center justify-center link" style="padding:0px; width: 40px; height: 40px;" title="回到定位位置">
        <i class="el-icon-aim font-size-16"></i>
      </div>
    </div>
    <div v-if="customerList.length" class="column flex-row align-items-center justify-center color-sub" style="position: absolute;background: rgba(255,255,255,.8); left:50%; bottom:10px;transform: translateX(-50%); padding:10px 20px">
      <i class="el-icon-location-information font-size-16"></i> <span class="color-default margin-lr-10">{{ currentAddress }}</span> <span class="color-orange link-text bold margin-lr-10" @click="nearCustomerVisible=true">查看附近的客户</span>
    </div>

    <el-dialog
      custom-class="custom-dialog"
      title="客户详情"
      :visible.sync="customerDetail.visible"
      append-to-body
      top="5vh"
      width="60%"
    >
      <div style="height:calc(100vh - 10vh - 118px);overflow-y: auto;overflow-x: hidden;">
       <CustomerDetail :customerParams="customerDetail.info"></CustomerDetail>
      </div>
    </el-dialog>
    <el-drawer
      title="我的客户"
      :append-to-body="true"
      :size="500"
      :visible.sync="myCustomerVisible"
    >
      <MyCustomers v-if="myCustomerVisible" :coordinate="coordinate" @clickCustomer="viewCustomerDetail"></MyCustomers>
    </el-drawer>
    <el-drawer
      title="附近的客户"
      :append-to-body="true"
      :size="500"
      :visible.sync="nearCustomerVisible"
    >
      <NearCustomers v-if="nearCustomerVisible" :coordinate="coordinate" @clickCustomer="viewCustomerDetail"></NearCustomers>
    </el-drawer>
    <el-drawer
      title="铺货情况"
      :append-to-body="true"
      :size="500"
      :visible.sync="distributionOfGoodsVisible"
    >
      <DistributionOfGoods v-if="distributionOfGoodsVisible"></DistributionOfGoods>
    </el-drawer>
  </div>
    
  </template>
  <script>
  import AMapLoader from "@amap/amap-jsapi-loader";
  import currentMarkerImg from "@/assets/images/currentMarker.png"
  import customerMarkerImg_01 from "@/assets/images/marker-1.png"
  import customerMarkerImg_02 from "@/assets/images/marker-2.png"

  import CustomerListBySearch from "./components/CustomerListBySearch";
  import CustomerDetail from "./components/CustomerDetail"
  import MyCustomers from "./components/MyCustomers"
    import NearCustomers from "./components/NearCustomers"
    import DistributionOfGoods from "./components/DistributionOfGoods"
  export default {
    name: "customerMap",
    components:{CustomerListBySearch,CustomerDetail,MyCustomers,NearCustomers,DistributionOfGoods},
    props:{
     
    },
    data() {
      return {
        loading:false,
        AMap:null,
        map:null,
        customerLabel:null,
        zoom:15,//地图默认缩放
        zooms:[2,20],//地图缩放范围
        currentMarker:null,//当前位置Marker
        coordinate:[],//当前位置坐标
        currentAddress:'',//当前位置地址text
        customerList:[],//客户数据列表
        customerMarkers:null,
        customFilter:{
          user_state:3,
          khlb:'',
          user_state_list:[{name:'全部',value:3},{name:'已开户',value:1},{name:'未开户',value:0}],
          khlbList:[]
        },
        provinceData:{
          list:[],
          markers:[]
        },
        cityData:{
          list:[],
          markers:[]
        },
        districtData:{
          list:[],
          markers:[]
        },
        customerDetail:{
          visible:false,
          info:{}
        },
        myCustomerVisible:false,
        distributionOfGoodsVisible:false,//查看铺货弹层
        nearCustomerVisible:false,
      }
    },
    computed:{
      
    },
    mounted() {
      this.initAMap();
      
    },
    unmounted() {
      this.mapDestroy()
    },
    destroyed(){
     this.mapDestroy()
    },
    methods: {
      mapDestroy(){
        this.map?.destroy();
        this.currentMarker?.off('dragend',this.dragendCurrentMarker)
        this.map?.off('dblclick',this.dblclickMap)
        this.map?.off('zoomend',this.zoomendMap)
        this.customerMarkers?.off('click',this.clickCustomerMarker)
        this.customerMarkers?.off('mouseover',this.mouseoverCustomerMarker)
        this.customerMarkers?.off('mouseout',this.mouseoutCustomerMarker)
      },
      //初始化地图
      async initAMap() {
        await this.creatMap()
        let AMap=this.AMap
        //客户锚点label
        this.customerLabel = new AMap.Marker({
          map: this.map,
          content:' ',
          offset:[15,10]
        });
        //获取地区统计数据点
        this.getAggregation(1)
        this.getAggregation(2)
        this.getAggregation(3)
      },
      creatMap(){
       let promise = AMapLoader.load({
          key: "e5d9bafed04f7fd3c538203613c99a30", // 申请好的Web端开发者Key，首次调用 load 时必填
          version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          plugins: ['AMap.Geolocation','AMap.CitySearch'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        })
        promise.then((AMap) => {
          this.AMap=AMap
          //地图
          this.map = new AMap.Map("mapContainer", {
            // 设置地图容器id
            viewMode: "2D", // 是否为3D地图模式
            mapStyle: 'amap://styles/whitesmoke',  //设置地图的显示样式
            zoom: this.zoom, // 初始化地图级别
            zooms:this.zooms,
            doubleClickZoom:true
          });
           //添加控件  
          AMap.plugin(
            [
              "AMap.ToolBar",
              "AMap.Scale",
              "AMap.HawkEye",
              "AMap.MapType",
              "AMap.Geolocation",
              "AMap.ControlBar",
            ],
            ()=>{
              //添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
              this.map.addControl(new AMap.ToolBar());
              //添加比例尺控件，展示地图在当前层级和纬度下的比例尺
              this.map.addControl(new AMap.Scale());
              // this.map.addControl(new AMap.HawkEye());
            }
          ); 
          //定位
          AMap.plugin('AMap.Geolocation', ()=>{
            var geolocation = new AMap.Geolocation({
              enableHighAccuracy: true, // 是否使用高精度定位，默认：true
              timeout: 10000, // 设置定位超时时间，默认：无穷大
              offset: [10, 20],  // 定位按钮的停靠位置的偏移量
              zoomToAccuracy: true,  //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
              position: 'RB' //  定位按钮的排放位置,  RB表示右下
            })

            geolocation.getCityInfo((status,result)=>{
              if(status=='complete'){
                  this.onComplete(result)
              }else{
                this.onError(result)
              }
            });

            
          })  
        })
        promise.catch((e) => {
          console.log(e)
          this.$message({message:'地图加载失败',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        });
        return promise
      },
      //定位完成回调
      onComplete(data) {
        // data是具体的定位信息
        this.coordinate=data.position
        this.initSetCurrentMarker()
      },
      onError(data) {
        // 定位出错
        console.log(data)
        this. $message({message:'当前位置定位失败',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
      },
      //初次设置当前定位位置marker
      initSetCurrentMarker(){
        let AMap=this.AMap
        //当前位置点
        let icon = new AMap.Icon({
            size: new AMap.Size(32, 30),    // 图标尺寸
            image: currentMarkerImg,  // Icon的图像
            imageSize: new AMap.Size(32,30)   // 根据所设置的大小拉伸或压缩图片
        });
        this.currentMarker = new AMap.Marker({
            map:this.map,
            position: new AMap.LngLat(this.coordinate[0],this.coordinate[1]),
            anchor: 'bottom-center',
            topWhenClick:true,
            icon: icon, // 添加 Icon 图标
            zIndex:15,
            draggable:true,
            cursor:'move',
            title: '当前位置\n拖拽定位锚点或双击地图上位置更改定位',
        });
        this.currentMarker.on('dragend', this.dragendCurrentMarker);
        this.map.on('dblclick', this.dblclickMap);
        this.map.on('zoomend', this.zoomendMap);
      },
      //当前定位位置marker拖拽事件
      dragendCurrentMarker(e){
        this.coordinate=[e.lnglat.lng,e.lnglat.lat]
      },
      //双击地图定点改变当前定位事件
      dblclickMap(e){
        this.coordinate=[e.lnglat.lng,e.lnglat.lat]
        this.AMap.plugin('AMap.MoveAnimation',()=>{
            // 调用 moveTo 方法
            this.currentMarker.moveTo(this.coordinate, {
                duration: 100,
                delay: 100,
                autoRotation:false
            });
        });
      },
      //缩放地图事件
      zoomendMap(){
        let zoom=this.map.getZoom()
        this.zoom=zoom
      },
      //获取客户列表
      getCustomerList(){
        this.loading=true
        let params= {
          ...this.$store.state.basicParams,
          gdjd:this.coordinate[0],
          gdwd:this.coordinate[1],
          user_state:this.customFilter.user_state,
          khlb:this.customFilter.khlb
        }
        this.$instance.get('/khdt/get_map_user_data',{params})
        .then(res=>{
          this.loading=false
          if(res.data.code==0){
            this.customerList=res.data.data.list||[]
            this.customFilter.khlbList=res.data.data.khlb||[]
            if(this.customerMarkers){
              let data=this.customerList.map(v=>{return {lnglat:[v.gdjd,v.gdwd],name:v.dwmch,style:v.user_state!=0?0:1,extData:v}})
              this.customerMarkers.setData(data)
            }else{
              this.setCustomerMarker()
            }
          }
        })
        .catch(()=> {
          this.loading=false
        }); 
      },
      //设置客户marker
      setCustomerMarker(){
        let AMap=this.AMap
        let style = [
          {
            url: customerMarkerImg_01, //图标地址
            anchor: new AMap.Pixel(13, 13), //图标显示位置偏移量，基准点为图标左上角
            size: new AMap.Size(26, 26), //图标的尺寸
            zIndex: 3, //每种样式图标的叠加顺序，数字越大越靠前
          },
          {
            url: customerMarkerImg_02,
            anchor: new AMap.Pixel(13, 13), //图标显示位置偏移量，基准点为图标左上角
            size: new AMap.Size(26, 26), //图标的尺寸
            zIndex: 2,
          },
        ];
        let data=this.customerList.map(v=>{return {lnglat:[v.gdjd,v.gdwd],name:v.dwmch,style:v.user_state!=0?0:1,extData:v}})
        this.customerMarkers = new AMap.MassMarks(data,{
          zooms:[12,20], //在指定地图缩放级别范围内展示海量点图层
          style: style, //设置样式对象
        });
        this.customerMarkers.on('click',this.clickCustomerMarker)
        this.customerMarkers.on('mouseover', this.mouseoverCustomerMarker);
        this.customerMarkers.on('mouseout', this.mouseoutCustomerMarker);
        this.customerMarkers.setMap(this.map);
      },
      //客户marker事件
      clickCustomerMarker(e){
        
        this.customerDetail.info=e.data.extData
        this.customerDetail.visible=true
      },
      mouseoverCustomerMarker(e){
        this.customerLabel.setPosition(e.data.lnglat);
        this.customerLabel.setContent(
          `<div class="column" style="background:rgba(255,255,255,0.8); padding:5px; min-width:100px;">
            <p class="text-nowrap">${e.data.extData.dwmch}</p>
            <p class='color-sub font-size-12'>${e.data.extData.dzhdh}</p>
          </div>`
        )
      },
      mouseoutCustomerMarker(){
        this.customerLabel.setContent(' ')
      },
      //根据定位坐标获取当前地址TEXT
      getAddress(){
        let AMap=this.AMap
        AMap.plugin('AMap.Geocoder', () => {
        let geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: "all"
        });
        geocoder.getAddress(this.coordinate, (status, result)=> {
            if (status === 'complete' && result.info === 'OK') {
                if (result.regeocode && result.regeocode.formattedAddress) {
                    let address = result.regeocode.formattedAddress;
                    this.currentAddress=address
                } else {
                    console.error('获取地址失败');
                }
            } else {
                console.error('获取地址失败');
            }
        });
    })
      },
       //获取聚合数据
      getAggregation(type){
        let params= {
          ...this.$store.state.basicParams,
          tj_type:type,
          user_state:this.customFilter.user_state,
          khlb:this.customFilter.khlb
        }
        this.$instance.get('/khdt/get_map_tj_data',{params})
        .then(res=>{
          if(res.data.code==0){
            switch(type){
              case 1:
                this.districtData.list=res.data.data.list||[];
                break;
              case 2:
                this.cityData.list=res.data.data.list||[];
                break;
              case 3:
                this.provinceData.list=res.data.data.list||[];
                break;
            }
            this.setAggregationMarker(type)
          }
        })
        .catch(()=> {}); 
      },
       //设置聚合marker
      setAggregationMarker(type){
        let AMap=this.AMap
        let areaData=type==1?this.districtData:type==2?this.cityData:type==3?this.provinceData:this.districtData
        this.map.remove(areaData.markers); //清除 marker
        for(let item of areaData.list.filter(v=>v.gdjd&&v.gdwd)){
          const markerContent = `<div class="flex-column align-items-center justify-center" style="width:50px;height:50px;border-radius:100%;background:#fe5e12;color:#fff;box-shadow: 0 0 10px rgba(0,0,0,0.2);">
                                  <div class="text-overflow-ellipsis font-size-12 text-align-center" style="width:50px">${item.area_name}</div>
                                  <div>${item.tj_count}</div>
                                  </div>`
          let marker=new AMap.Marker({
              map:this.map,
              position: new AMap.LngLat(item.gdjd,item.gdwd),
              anchor: 'center',
              topWhenClick:true,
              content: markerContent,
              zIndex:12+item.user_state,
              zooms:type==1?[9,11.99]:type==2?[7,8.99]:[2,6.99]
          });
          areaData.markers.push(marker)
        }
        
      },
      setMapZoom(){
        this.map?.setZoom(this.zoom,false,300)
      },
      setZoomAndCenter(){
        this.map?.setZoomAndCenter(15,this.coordinate,false,300)
      },
      //搜索列表打开客户详情
      viewCustomerDetail(data){
        
        this.customerDetail.info=data
        this.customerDetail.visible=true
      },
    },
    watch: {
      'coordinate':{
        handler(){
          this.getAddress()
          this.getCustomerList()
        },
        deep:true
      },

    }
  };
  </script>
  <style scoped>
  #mapContainer {
    width: 100%;
    height: calc(100vh - 160px);
  }
  </style>
  <style>
 #mapContainer .el-loading-mask{
      background-color: rgba(255, 255, 255, 0.1);
  }
</style>