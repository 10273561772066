<template>
  <div v-loading="loading" style="height: 100%; position: relative;">
      <div class="padding-lr-10" style="height: calc(100% - 60px); overflow: auto;">
        <div id="customerDetailMap" ></div>
        <template v-if="customerDetail&&customerDetail.user_state!=0">
            <div>
              <div class="padding-tb-20">
                <div>
                  <span class="custom-tag margin-right-5" :color="['','blue','green'][customerDetail.user_state]">{{['未开户','已开户','活跃用户'][customerDetail.user_state] }}</span>
                  <span class="font-size-18 bold">{{ customerDetail.dwmch }}</span>
                  <span class="color-sub">（{{ customerDetail.danwbh }}）</span>
                </div>
                <div class="flex-row align-items-center color-blue bold margin-top-5">
                  <div class="margin-right-20">本月采购{{ customerDetail.bycg }}</div>
                  <div>上月采购{{ customerDetail.sycg }}</div>
                </div>
                <div class="margin-top-5 color-yellow">
                  {{ customerDetail.user_type }}
                </div>
                <div class="flex-row align-items-center justify-between margin-top-5">
                  <div class="color-sub"><i class="el-icon-location-information"></i> {{ customerParams.dzhdh }}</div>
                  <div class="text-nowrap margin-left-10 padding-left-10 border-left">{{ customerParams.distance }}</div>
                </div>
                <div class="margin-top-5"><span>客户信息不对？</span><span class="link-text color-yellow" @click="commitUserInfoVisible=true">去反馈</span></div>
              </div>
              <el-tabs v-model="activeTab" type="card">
                <el-tab-pane label="客户资料" name="khzl"></el-tab-pane>
                <el-tab-pane label="历史采购" name="lscg"></el-tab-pane>
              </el-tabs>
              <div v-if="activeTab=='khzl'">
                <div class="column flex-row align-items-center justify-between">
                  <div class="bold">
                    客户证照 
                    <span :class="`margin-left-5 ${['','color-green','color-yellow','color-red'][customerDetail.zizhi_state]}`">{{ ['','全部正常','即将超期','已超期'][customerDetail.zizhi_state] }}</span>
                  </div>
                  <span class="link-text margin-left-20 color-blue" @click="ziliaoVisible=true"><i class="el-icon-postcard"></i> 查看证照</span>
                </div>
                <div class="khhx column">
                  <div class=" flex-row align-items-center justify-between padding-bottom-20 border-bottom">
                    <div class="bold">客户画像</div>
                    <span class="link-text margin-left-20 color-blue" @click="khhxVisible=true"><i class="el-icon-edit"></i> 更新客户画像</span>
                  </div>
                  <ul>
                    <li>
                      <span>单位名称</span>
                      <span>{{customerDetail.khhx.dwmch}}</span>
                    </li>
                    <li>
                      <span>联系人电话</span>
                      <span>{{customerDetail.khhx.lxr_tel}}</span>
                    </li>
                    <li>
                      <span>单位地址</span>
                      <span>{{customerDetail.khhx.dzhdh}}</span>
                    </li>

                    <li>
                      <span>业务员姓名</span>
                      <span>{{customerDetail.khhx.ywy}}</span>
                    </li>
                    <li>
                      <span>客服姓名</span>
                      <span>{{customerDetail.khhx.kefu}}</span>
                    </li>
                    <li>
                      <span>备注信息</span>
                      <span>{{customerDetail.khhx.bz}}</span>
                    </li>
                    <li>
                      <span>账期</span>
                      <span>{{customerDetail.khhx.zq}}</span>
                    </li>
                    <li>
                      <span>配送费</span>
                      <span>{{customerDetail.khhx.psf}}</span>
                    </li>
                    <li>
                      <span>配送难度</span>
                      <span>{{customerDetail.khhx.psnd}}</span>
                    </li>
                    <li>
                      <span>毛利因素</span>
                      <span>{{customerDetail.khhx.mlys}}</span>
                    </li>
                    <li>
                      <span>销售规模</span>
                      <span>{{customerDetail.khhx.xsgm}}</span>
                    </li>
                    <li>
                      <span>客类</span>
                      <span>{{customerDetail.khhx.kl}}</span>
                    </li>
                    <li>
                      <span>客观指数</span>
                      <span>{{customerDetail.khhx.kgzs}}</span>
                    </li>
                    <li>
                      <span>定价指数</span>
                      <span>{{customerDetail.khhx.djzz}}</span>
                    </li>
                    <li>
                      <span>指数毛利率</span>
                      <span>{{customerDetail.khhx.zsmll}}</span>
                    </li>
                    <li>
                      <span>预期毛利率</span>
                      <span>{{customerDetail.khhx.yqmll}}</span>
                    </li>
                    <li>
                      <span>定价等级</span>
                      <span>{{customerDetail.khhx.djdj}}</span>
                    </li>
                    <li>
                      <span>定价指标</span>
                      <span>{{customerDetail.khhx.djzb}}</span>
                    </li>
                    <li>
                      <span>药店面积</span>
                      <span>{{customerDetail.khhx.ydmj}}</span>
                    </li>
                    <li>
                      <span>周期</span>
                      <span>{{customerDetail.khhx.zhq}}</span>
                    </li>
                  </ul>
                </div>
              </div> 
              <div v-if="activeTab=='lscg'">
                <div class="flex-row justify-end">
                  <div class="link color-yellow" @click="historyBuy.viewMore==0?historyBuy.viewMore=1:historyBuy.viewMore=0;getHistoryBuy()">{{historyBuy.viewMore==0?'查看更多月份':'查看最近月份'}}</div>
                </div>
                <el-table
                :data="historyBuy.list"
                :max-height="$root.docHeight-200"
                :key="customerParams.danwbh+'_'+historyBuy.viewMore"
                 @sort-change="historyBuySort"
                size="small"
                 style="width: 100%">
                 <el-table-column label="商品名称" min-widht="350" fixed>
                  <template slot-scope="props">
                   <div>{{props.row.spmc}}</div>
                   <div class="color-sub">{{props.row.spgg}}</div>
                   <div class="color-sub">{{props.row.shengccj}}</div>
                  </template>
                </el-table-column>
                <template v-if="historyBuy.list&&historyBuy.list.length">
                  <el-table-column :label="item.year_month" align="center" v-for="(item,index) in historyBuy.list[0].purchase_count_list" :key="item.year_month" :prop="item.year_month" sortable="custom">
                    <template slot-scope="props">
                    <div>{{props.row.purchase_count_list[index].value}}</div>
                    </template>
                  </el-table-column>
                </template>
                </el-table>
              </div>
            </div>
        </template>
        <template v-if="customerDetail&&customerDetail.user_state==0">
          <div class="flex-column" style="height: calc(100% - 300px)">
            <div class="border-bottom padding-tb-20">
              <div>
                <span class="custom-tag margin-right-5" :color="['','blue','green'][customerDetail.user_state]">{{['未开户','已开户','活跃用户'][customerDetail.user_state] }}</span>
                <span class="font-size-18 bold">{{ customerDetail.dwmch }}</span>
                <span class="color-sub">（{{ customerDetail.danwbh }}）</span>
              </div>
              <div class="flex-row align-items-center justify-between margin-top-5">
                <div class="color-sub"><i class="el-icon-location-information"></i> {{ customerParams.dzhdh }}</div>
                <div class="text-nowrap margin-left-10 padding-left-10 border-left">{{ customerParams.distance }}</div>
              </div>
              <div class="margin-top-5"><span>客户信息不对？</span><span class="link-text color-yellow" @click="commitUserInfoVisible=true">去反馈</span></div>
            </div>
            <el-empty description="该客户暂未开户，请点击下方“去开户”按钮" :image="noConPic" class="flex-auto">
              <!-- <el-button type="primary">去开户</el-button> -->
            </el-empty>
          </div>
        </template>
      </div>
      <div v-if="customerDetail" class="bottom-bar padding-top-10 flex-row align-items-center justify-between">
        <div class="link font-size-16" @click="showTel"><i class="el-icon-phone-outline color-orange bold"></i> 联系人</div>
        <div>
          <el-button round @click="visit">添加拜访</el-button>
          <el-button type="primary" round v-if="customerDetail.user_state!=0" @click="proxyReportingPlan">代报计划</el-button>
          <el-button type="primary" round v-else @click="quickReg">去开户</el-button>
        </div>
      </div>

      <el-dialog
        custom-class="custom-dialog"
        :title="`客户证照（${(customerDetail||{}).dwmch||''}）`"
        :visible.sync="ziliaoVisible"
        append-to-body
        top="5vh"
        width="60%"
      >
        <div style="height:calc(100vh - 10vh - 118px);overflow-y: auto;overflow-x: hidden;">
          <CustomerCertificate v-if="ziliaoVisible" :customerParams="customerParams"></CustomerCertificate>
        </div>
      </el-dialog>
      <el-dialog
        custom-class="custom-dialog"
        :title="`更新客户画像（${(customerDetail||{}).dwmch||''}）`"
        :visible.sync="khhxVisible"
        append-to-body
        top="5vh"
        width="60%"
      >
        <div style="height:calc(100vh - 10vh - 118px);overflow-y: auto;overflow-x: hidden;">
          <CustomerPortrait v-if="khhxVisible" :khhx="customerDetail.khhx||{}" :customerParams="customerParams" @updateDetail="getCustomerDetail();khhxVisible=false"></CustomerPortrait>
        </div>
      </el-dialog>
      <el-dialog
        custom-class="custom-dialog"
        :title="`信息反馈（${(customerDetail||{}).dwmch||''}）`"
        :visible.sync="commitUserInfoVisible"
        append-to-body
        top="5vh"
        width="60%"
      >
        <div style="height:calc(100vh - 10vh - 118px);overflow-y: auto;overflow-x: hidden;">
          <CommitUserInfo v-if="commitUserInfoVisible" :customerParams="customerParams" @updateDetail="commitUserInfoVisible=false"></CommitUserInfo>
        </div>
      </el-dialog>
  </div>
    
  </template>
  <script>
  import AMapLoader from "@amap/amap-jsapi-loader";
  import noConPic from "@/assets/images/no-con.png"
  import customerMarkerImg_01 from "@/assets/images/marker-1.png"
  import customerMarkerImg_02 from "@/assets/images/marker-2.png"

  import CustomerCertificate from "./CustomerCertificate"
  import CustomerPortrait from "./CustomerPortrait"
  import CommitUserInfo from "./CommitUserInfo"
  export default {
    name: "CustomerDetail",
    components:{CustomerCertificate,CustomerPortrait,CommitUserInfo},
    props:{
      customerParams:{
        type:Object,
        default:()=>{
          return {}
        }
      }
    },
    data() {
      return {
        noConPic,
        loading:false,
        AMap:null,
        map:null,
        zoom:15,//地图默认缩放
        zooms:[2,20],//地图缩放范围
        currentMarker:null,//客户位置Marker
        customerDetail:null,
        activeTab:'khzl',
        historyBuy:{
          list:[],
          viewMore:0
        },//历史采购
        ziliaoVisible:false,//查看证照弹层
        khhxVisible:false,//更新客户画像弹层
        commitUserInfoVisible:false,//反馈客户信息弹层
      }
    },
    computed:{
      
    },
    mounted() {
      this.initAMap();
      this.getCustomerDetail()
      this.getHistoryBuy()
    },
    unmounted() {
      this.mapDestroy()
    },
    destroyed(){
     this.mapDestroy()
    },
    methods: {
      mapDestroy(){
        this.map?.destroy();
      },
      //初始化地图
      async initAMap() {
        await this.creatMap()
      },
      creatMap(){
       let promise = AMapLoader.load({
          key: "e5d9bafed04f7fd3c538203613c99a30", // 申请好的Web端开发者Key，首次调用 load 时必填
          version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          plugins: ['AMap.Geolocation','AMap.CitySearch'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        })
        promise.then((AMap) => {
          this.AMap=AMap
          //地图
          this.map = new AMap.Map("customerDetailMap", {
            // 设置地图容器id
            viewMode: "2D", // 是否为3D地图模式
            mapStyle: 'amap://styles/whitesmoke',  //设置地图的显示样式
            zoom: this.zoom, // 初始化地图级别
            zooms:this.zooms,
            doubleClickZoom:true
          });
           //添加控件  
          AMap.plugin(
            [
              "AMap.ToolBar",
              "AMap.Scale",
              "AMap.HawkEye",
              "AMap.MapType",
              "AMap.Geolocation",
              "AMap.ControlBar",
            ],
            ()=>{
              //添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
              this.map.addControl(new AMap.ToolBar());
              //添加比例尺控件，展示地图在当前层级和纬度下的比例尺
              this.map.addControl(new AMap.Scale());
              // this.map.addControl(new AMap.HawkEye());
            }
          );    
        })
        promise.catch((e) => {
          console.log(e)
          this.$message({message:'地图加载失败',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        });
        return promise
      },
      //设置当前客户位置marker
      setCurrentMarker(){
        this.currentMarker&&this.currentMarker.remove()
        let AMap=this.AMap
        let content=[
          `<div><img width="26" height="26" src="${customerMarkerImg_01}"></div>`,
          `<div><img width="26" height="26" src="${customerMarkerImg_02}"></div>`
        ]
        let position=this.customerDetail.gdjd&&this.customerDetail.gdwd? new AMap.LngLat(this.customerDetail.gdjd,this.customerDetail.gdwd):undefined
        //位置点
        this.currentMarker = new AMap.Marker({
            map:this.map,
            position: position,
            anchor: 'center',
            topWhenClick:true,
            content: this.customerDetail.user_state==0?content[1]:content[0], 
            zIndex:15,
        });
        position&&this.map?.setZoomAndCenter(15,[this.customerDetail.gdjd,this.customerDetail.gdwd],false,300)
      },
     
      //获取客户详情
      getCustomerDetail(){
        this.customerDetail=null
        this.loading=true
        let params= {
          ...this.$store.state.basicParams,
          dwbh:this.customerParams.dwbh
        }
        this.$instance.get('/khdt/get_user_info',{params})
        .then(res=>{
          this.loading=false
          if(res.data.code==0){
            this.customerDetail=res.data.data||null
            this.setCurrentMarker()//添加客户位置marker
          }
        })
        .catch(()=> {
          this.loading=false
        }); 
      },
      showTel(){
        let message=`
        <div>
            <div class="flex-row align-items-center justify-between border-bottom padding-tb-5">
              <div>客服：${this.customerDetail.lxfs.kefu}</div>
              <div><i class="el-icon-phone-outline color-orange bold"></i>${this.customerDetail.lxfs.kefu_tel}</div>
            </div>
            <div class="flex-row align-items-center justify-between border-bottom padding-tb-5">
              <div>联系人：${this.customerDetail.lxfs.lxr}</div>
              <div><i class="el-icon-phone-outline color-orange bold"></i>${this.customerDetail.lxfs.lxr_tel}</div>
            </div>
            <div class="flex-row align-items-center justify-between border-bottom padding-tb-5">
              <div>业务员：${this.customerDetail.lxfs.ywy}</div>
              <div><i class="el-icon-phone-outline color-orange bold"></i>${this.customerDetail.lxfs.ywy_tel}</div>
            </div>
          </div>
        `
        this.$alert(message, '联系方式', {
            customClass:"custom-msgbox",
            dangerouslyUseHTMLString:true,
            confirmButtonText: '知道了',
            center: true,
            showClose:false,
            type: 'info'
          })
          .then(() => {})
      },
      //获取客户采购历史
      getHistoryBuy(){
        let params= {
          ...this.$store.state.basicParams,
          dwbh:this.customerParams.dwbh,
          sort_type:'asc',
          view_more:this.historyBuy.viewMore
        }
        this.$instance.get('/khdt/get_purchase_summary_data_list',{params})
        .then(res=>{

          if(res.data.code==0){
            this.historyBuy.list=res.data.data||[]
          }
        })
        .catch(()=> {

        }); 
      },
      //采购历史排序
      historyBuySort({order,prop}){
        if(order=="ascending"){
            //升序
            this.historyBuy.list = this.historyBuy.list.sort((a, b) => {
              return  a.purchase_count_list.find(v=>v.year_month==prop).value-b.purchase_count_list.find(v=>v.year_month==prop).value
            })
        }else{
          this.historyBuy.list = this.historyBuy.list.sort((a, b) => {
            return  b.purchase_count_list.find(v=>v.year_month==prop).value-a.purchase_count_list.find(v=>v.year_month==prop).value
          })
        }
        },
        //代报计划
      proxyReportingPlan(){
        if(this.customerDetail){
          let val={
            cq_count:this.customerDetail.cq_count,
            danwbh:this.customerDetail.danwbh,
            dwbh:this.customerDetail.dwbh,
            dwmch:this.customerDetail.dwmch,
            dzhdh:this.customerDetail.dzhdh,
            rnum:this.customerDetail.rnum,
            user_id:this.customerDetail.user_id,
            }
          if(val.cq_count){
            this.$message({message: '用户资质信息超期，暂无法代报计划',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
          }else{
            try{
            localStorage.setItem('currCustomer',JSON.stringify(val))
            }catch{
              //
            }
            this.$store.commit('currCustomer',val)
            this.$root.jump({path:'/mall/prodList'})
          }
       }
      },
      //开户
      quickReg(){
        let params= {
          ...this.$store.state.basicParams,
          dwbh:this.customerParams.dwbh,
        }
        this.$instance.get('/khdt/quick_reg',{params})
        .then(res=>{
          if(res.data.code==0){
            this.$message({message: res.data.msg,type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
          }
        })
        .catch(()=> {

        }); 
      },
       //拜访
      visit(){
        this.$message({message: '敬请期待',type:'info',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
      },
    },
    watch: {
      'customerParams':{
        handler(){
          this.activeTab='khzl'
          this.historyBuy.viewMore=0
          this.getCustomerDetail()
          this.getHistoryBuy()
        },
        deep:true
      },

    }
  };
  </script>
  <style scoped  lang="scss">
  #customerDetailMap {
    width: 100%;
    height:300px;
  }
  .khhx{
    li{
      display: flex;
      justify-content: space-between;
      border-bottom: #e7e7e7 solid 1px;
      padding:10px;
      span:first-child{
        color: #999;
        white-space: nowrap;
        margin-right:10px;
      }
    }
  }
  .bottom-bar{
    height: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  </style>
  <style>
 #customerDetailMap .el-loading-mask{
      background-color: rgba(255, 255, 255, 0.1);
  }
</style>