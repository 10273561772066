<template>
  <div>
    <div class="search">
      <el-input placeholder="请输入客户名称检索" clearable v-model.trim="keyword" @keyup.enter.native="initSearch" style="width: 400px;">
        <el-button slot="append" icon="el-icon-search" @click="initSearch"></el-button>
      </el-input>
    </div>
    <el-drawer
      title="客户列表"
      :append-to-body="true"
      :size="500"
      :visible.sync="drawer"
    >
    <div slot="title">
      <el-input placeholder="请输入客户名称检索" clearable v-model.trim="keyword" @keyup.enter.native="initSearch" style="width: 400px;">
        <el-button slot="append" icon="el-icon-search" @click="initSearch"></el-button>
      </el-input>
    </div>
    
    <div v-if="list.length" class="infinite-list padding-lr-20" v-infinite-scroll="load" infinite-scroll-distance="10" infinite-scroll-immediate="false" :infinite-scroll-disabled="scrollDisabled">
      <div class="padding-tb-10 border-bottom" v-for="(item,index) in list" :key="index">
        <div class="link" @click="clickCustomer(item)">
          <span class="custom-tag margin-right-5" :color="item.user_state==0?'':item.user_state==1?'blue':item.user_state==2?'green':''">{{ item.user_state==0?'未开户':item.user_state==1?'已开户':item.user_state==2?'活跃用户':'' }}</span>
          <span class="font-size-16">{{ item.dwmch }}</span>
          <span class="color-sub">（{{ item.danwbh }}）</span>
        </div>
        <div class="color-yellow font-size-12 margin-top-5">{{ item.user_type }}</div>
        <div class="flex-row align-items-center justify-between margin-top-5">
          <div class="color-sub">{{ item.dzhdh }}</div>
          <div class="text-nowrap margin-left-10 padding-left-10 border-left">{{ item.distance }}</div>
        </div>
      </div>
      <div v-if="loading" class="color-sub font-size-12 padding-20 text-align-center"><i class="el-icon-loading"></i> 数据加载中</div>
      <div v-if="scrollDisabled&&!loading" class="color-sub font-size-12 padding-20 text-align-center">加载完成</div>
    </div>
    <el-empty v-else :image-size="100" style="height: 100%;"> </el-empty>
    </el-drawer>
  </div>
    
  </template>
  <script>
  
  export default {
    name: "CustomerListBySearch",
    props:{
      coordinate:{
        type:Array,
        default:()=>{
          return []
        }
      }
    },
    data() {
    return {
      keyword:'',
      drawer:false,
      list:[],
      pageNum:1,
      pageSize:30,
      scrollDisabled:false,
      loading:false
    }
  },
    mounted() {
      
    },
    methods: {
      initSearch(){
        if(this.keyword){
         this.drawer=true
         this.list=[]
         this.pageNum=1
         this.scrollDisabled=false
         this.load()
        }else{
          this. $message({message:'请输入搜索关键字',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        }
      },
      load(){
        this.scrollDisabled=true
        this.loading=true
        let params= {
          ...this.$store.state.basicParams,
          page:this.pageNum,
          page_size:this.pageSize,
          keyword:this.keyword,
          gdjd:this.coordinate[0],
          gdwd:this.coordinate[1]
        }
        this.$instance.get('/khdt/get_search_user_list',{params})
        .then(res=>{
          this.loading=false
          this.scrollDisabled=false
          if(res.data.code==0){
            this.list=this.list.concat(res.data.data.list||[])
            
            //是否全部加载完成
            if(this.list.length>=res.data.data.total){
              this.scrollDisabled=true
            }else{this.pageNum+=1}
          }
        })
        .catch(()=> {
          this.loading=false
          this.scrollDisabled=false
        }); 
      },
      clickCustomer(data){
        this.$emit('clickCustomer',data)
      }
    },
    watch: {
      
    }
  };
  </script>
  <style scoped>
  .search{
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 99;
  }
  .infinite-list{
    height: 100%;
    overflow: auto;
  }
  </style>